@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.simplebar-content-wrapper {
  /* in notification dropdown black borders appear when focus. fix below... */
  outline: none !important;
}

.item {
  align-items: center;
  color: black;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.disabled-page {
  color: #808e9b;
}

.active-pagination {
  border: solid 2px red !important;
  border-radius: 40px;
  /* color: white; */
  /* background-color: red; */
  font-weight: 700 !important;
}

.pagination {
  align-items: center;
  /* background-color: #0fbcf9; */
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: 1000px;
}

.pagination-page {
  font-weight: 400;
  margin: 5px;
  border: 2px solid grey;
  border-radius: 7px;
  padding: 20px 30px;
}

.pagination-page a {
  padding: 20px 40px;
}
